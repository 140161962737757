import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProgramGlobalState, ProgramUserInfo } from "Types/program";

import { Percentage, SessionReducer, Tabs, StakingTokens } from "Types/reducers";
import { formatUnits } from "Utils/format";

const initialState: SessionReducer = {
  currentTab: Tabs.stake,
  currentToken: StakingTokens.mesh,
  amount: "",
  percentage: Percentage._0,
  meshBalance: "0",
  indexMeshBalance: "0",
  proMode: false,
  userIndexMeshATA: undefined,
  userMeshATA: undefined,
  globalState: undefined,
  userStakingInfoAddress: undefined,
  successTxCount: 0,
  isInSufficientBalance: false,
  pendingMeshReward: "0",
  pendingIndexMeshReward: "0",
  userStakingInfo: {
    stakedMesh: "0",
    stakedIndexMesh: "0",
    totalClaimedReward: "0",
  },
  meshAPY: "0",
  indexMeshAPY: "0",
  totalPendingReward: "0",
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    setCurrentTab(state, action: PayloadAction<Tabs>) {
      state.currentTab = action.payload;
    },

    setCurrentToken(state, action: PayloadAction<StakingTokens>) {
      state.currentToken = action.payload;
    },
    setAmount(state, action: PayloadAction<string>) {
      state.amount = action.payload;
    },
    setPercentage(state, action: PayloadAction<Percentage>) {
      state.percentage = action.payload;
    },
    setMeshBalance(state, action: PayloadAction<string>) {
      state.meshBalance = action.payload;
    },
    setIndexMeshBalance(state, action: PayloadAction<string>) {
      state.indexMeshBalance = action.payload;
    },
    setProMode(state, action: PayloadAction<boolean>) {
      state.proMode = action.payload;
    },
    setUserMeshATA(state, action: PayloadAction<string | undefined>) {
      state.userMeshATA = action.payload;
    },
    setUserIndexMeshATA(state, action: PayloadAction<string | undefined>) {
      state.userIndexMeshATA = action.payload;
    },
    setUserStakingInfoAddress(state, action: PayloadAction<string | undefined>) {
      state.userStakingInfoAddress = action.payload;
    },
    setIsInsufficientBalance(state, action: PayloadAction<boolean>) {
      if (state.isInSufficientBalance !== action.payload) {
        state.isInSufficientBalance = action.payload;
      }
    },
    incrementSuccessTxCount(state) {
      state.successTxCount = state.successTxCount + 1;
    },
    setGlobalState(state, action: PayloadAction<ProgramGlobalState>) {
      if (action.payload !== undefined) {
        const {
          reward,
          distributionTime,
          weightage,
          totalStakedMesh,
          totalStakedIndexMesh,
          totalDistributedReward,
          periodEndTime,
          meshMint,
          lastUpdatedTime,
          indexMeshMint,
          governance,
          globalAccRewardMesh,
          globalAccRewardIndexMesh,
        } = action.payload.account;

        state.globalState = {
          account: {
            distributionTime: distributionTime.toNumber(),
            globalAccRewardIndexMesh: formatUnits(globalAccRewardIndexMesh),
            globalAccRewardMesh: formatUnits(globalAccRewardMesh),
            governance: governance.toString(),
            indexMeshMint: indexMeshMint.toString(),
            lastUpdatedTime: lastUpdatedTime.toNumber(),
            meshMint: meshMint.toString(),
            periodEndTime: periodEndTime.toNumber(),
            reward: formatUnits(reward),
            totalDistributedReward: formatUnits(totalDistributedReward),
            totalStakedIndexMesh: formatUnits(totalStakedIndexMesh),
            totalStakedMesh: formatUnits(totalStakedMesh),
            weightage: formatUnits(weightage),
          },
          publicKey: action.payload.publicKey.toString(),
        };
      }
    },
    resetSessionState(state) {
      state.currentTab = Tabs.stake;
      state.currentToken = StakingTokens.mesh;
      state.amount = "";
      state.percentage = Percentage._0;
    },
    setPendingRewards(state, action: PayloadAction<{ mesh: string; indexMesh: string }>) {
      state.pendingMeshReward = action.payload.mesh;
      state.pendingIndexMeshReward = action.payload.indexMesh;
      state.totalPendingReward = (
        Number(action.payload.mesh) + Number(action.payload.indexMesh)
      ).toString();
    },

    setUserStakingInfo(state, action: PayloadAction<ProgramUserInfo | undefined>) {
      if (action.payload !== undefined) {
        state.userStakingInfo = {
          accRewardIndexMesh: formatUnits(action.payload.accRewardIndexMesh).toString(),
          accRewardMesh: formatUnits(action.payload.accRewardMesh).toString(),
          stakedIndexMesh: formatUnits(action.payload.stakedIndexMesh).toString(),
          stakedMesh: formatUnits(action.payload.stakedMesh).toString(),
          totalClaimedReward: formatUnits(action.payload.totalClaimedReward).toString(),
          user: action.payload.user.toString(),
        };
      } else {
        state.userStakingInfo = {
          stakedIndexMesh: "0",
          stakedMesh: "0",
          totalClaimedReward: "0",
        };
      }
    },
    setAPY(state, action: PayloadAction<{ meshAPY: string; indexMeshAPY: string }>) {
      state.meshAPY = action.payload.meshAPY;
      state.indexMeshAPY = action.payload.indexMeshAPY;
    },
  },
});

export default session.reducer;

export const {
  setGlobalState,
  setUserStakingInfoAddress,
  setIndexMeshBalance,
  setAPY,
  setCurrentTab,
  setCurrentToken,
  setAmount,
  setPercentage,
  setMeshBalance,
  resetSessionState,
  setProMode,
  setUserIndexMeshATA,
  setUserMeshATA,
  incrementSuccessTxCount,
  setIsInsufficientBalance,
  setPendingRewards,
  setUserStakingInfo,
} = session.actions;
