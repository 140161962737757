export enum Tabs {
  stake = "stake",
  unstake = "unstake",
  claim = "claim",
}

export enum StakingTokens {
  mesh = "MESH",
  indexedMesh = "indexMESH",
}

export type SessionReducer = {
  currentTab: Tabs;
  currentToken: StakingTokens;
  amount: string;
  percentage: Percentage;
  meshBalance: string;
  indexMeshBalance: string;
  proMode: boolean;
  userMeshATA: string | undefined;
  userIndexMeshATA: string | undefined;
  globalState:
    | {
        account: {
          distributionTime: number;
          globalAccRewardIndexMesh: number;
          globalAccRewardMesh: number;
          governance: string;
          indexMeshMint: string;
          lastUpdatedTime: number;
          meshMint: string;
          periodEndTime: number;
          reward: number;
          totalDistributedReward: number;
          totalStakedIndexMesh: number;
          totalStakedMesh: number;
          weightage: number;
        };
        publicKey: string;
      }
    | undefined;

  userStakingInfoAddress: string | undefined;
  successTxCount: number;
  isInSufficientBalance: boolean;
  pendingMeshReward: string;
  pendingIndexMeshReward: string;
  userStakingInfo: {
    stakedIndexMesh: string;
    stakedMesh: string;
    totalClaimedReward: string;
    accRewardIndexMesh?: string;
    accRewardMesh?: string;
    user?: string;
  };
  meshAPY: string;
  indexMeshAPY: string;
  totalPendingReward: string;
};

export type AppReducer = {
  meshPrice: number;
  solPrice: number;
  indexMeshPrice: number;
  stakeInfoBannerClosed: string;
  isTimerCompleted: boolean;
  isScreenTimerCompleted: boolean;
  isBannerTimerCompleted: boolean;
  getMeshBannerClosed: string;
  rpc: RPC;
};
export type LoadingsReducer = {
  isPendingRewardLoading: boolean;
  isPriceLoading: boolean;
  isUserStakingInfoLoading: boolean;
  isGlobalStateLoading: boolean;
  isTxInProgress: boolean;
};

export enum RPC {
  _1 = 1,
  _2 = 2,
}

export enum Percentage {
  _0 = 0,
  _10 = 0.1,
  _25 = 0.25,
  _50 = 0.5,
  _75 = 0.75,
  _100 = 1,
}
