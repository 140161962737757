import { ReactNode } from "react";

import Header from "./header";
// import { Outlet } from "react-router-dom";
import Footer from "./footer";
// import Countdown from "Components/countdown";
// import { useSelector } from "Store";

const MainLayout = ({ children }: { children: ReactNode }) => {
  // const { isScreenTimerCompleted } = useSelector((state) => state.app);

  return (
    <>
      {/* {!isTimerCompleted && <Countdown />} */}
      {<Header />}
      {children}
      {/* {<Outlet />} */}
      <Footer />
    </>
  );
};

export default MainLayout;
