import CrossIcon from "Assets/Svgs/cross.svg";
import { RADIYUM_URL } from "Constants/misc";

type Props = {
  onCrossClick?: () => void;
  TwContainerClass?: string;
};

export const GetMeshBanner = ({ onCrossClick, TwContainerClass = "" }: Props) => {
  return (
    <div
      className={`mesh-gradient p-2 text-sm flex items-center justify-center ${TwContainerClass}`}
    >
      <div className="w-[80%] flex justify-center underline">
        <a
          href={RADIYUM_URL}
          target="_blank"
          rel="noreferrer"
          className="text-primary text-lg font-semibold"
        >
          Get $MESH on Raydium
        </a>
      </div>
      {onCrossClick && (
        <img src={CrossIcon} alt={"CrossIcon"} className="cursor-pointer" onClick={onCrossClick} />
      )}
    </div>
  );
};
