export const BoldTextSkeleton = () => {
  return (
    <div role="status" className="animate-pulse">
      <div className="ml-1 h-4 bg-gray-700 rounded-full w-9"></div>
    </div>
  );
};

export const SmallTextSkeleton = () => {
  return (
    <div role="status" className="animate-pulse">
      <div className="ml-1 h-2.5 bg-gray-700 rounded-full w-7"></div>
    </div>
  );
};

export const ChartSkeleton = () => {
  return (
    <div role="status" className="animate-pulse">
      <div className="h-44 bg-gray-700 rounded-full w-44"></div>
    </div>
  );
};
