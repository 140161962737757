import ringMeshIcon from "Assets/Svgs/ring-mesh.svg";
import { useSelector } from "Store";
import { truncateDigits } from "Utils/format";
import { BoldTextSkeleton } from "./states/textSkeleton";
import { Tooltip } from "./tooltip";

export const TokenPrice = () => {
  const { meshPrice } = useSelector((state) => state.app);
  const { isPriceLoading } = useSelector((state) => state.loadings);

  return (
    <Tooltip text={"$" + Number(meshPrice.toFixed(4)).toString()}>
      <div className="mr-4 sm:mr-4 px-3 py-2 flex items-center bg-base-content rounded">
        <img src={ringMeshIcon} alt="ringMeshIcon" width={25} height={25} />
        {isPriceLoading ? (
          <BoldTextSkeleton />
        ) : (
          <p className="text-primary font-extrabold px-1.5">${truncateDigits(meshPrice)}</p>
        )}
      </div>
    </Tooltip>
  );
};
