import { useCallback, useEffect, useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { WalletError, WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import * as walletAdapterWallets from "@solana/wallet-adapter-wallets";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider, BaseWalletMultiButton } from "Components/walletModal";
import * as MobileAdapter from "@solana-mobile/wallet-adapter-mobile";
import { clusterApiUrl } from "@solana/web3.js";
import { ToastContainer } from "react-toastify";

import Routes from "./Routes";
import { dispatch, useSelector } from "Store";
import { Environment } from "Types/misc";
import { RPC } from "Types/reducers";
import { HELIUS_DEVNET_RPC, HELIUS_MAINNET_RPC, QUICKNODE_MAINNET_RPC } from "Constants/endpoints";
import "react-toastify/dist/ReactToastify.css";
import { incrementSuccessTxCount } from "Store/Reducers/session";
require("@solana/wallet-adapter-react-ui/styles.css");

export const WalletButton = BaseWalletMultiButton;

function App() {
  const { rpc } = useSelector((state) => state.app);
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/*",
          element: <Routes />,
          errorElement: <></>,
        },
      ]),
    []
  );
  const wallets = useMemo(
    () => [
      new walletAdapterWallets.SolongWalletAdapter(),
      new walletAdapterWallets.SafePalWalletAdapter(),
      new walletAdapterWallets.MathWalletAdapter(),
      new walletAdapterWallets.LedgerWalletAdapter(),
      new walletAdapterWallets.HuobiWalletAdapter(),
      new walletAdapterWallets.CoinbaseWalletAdapter(),
      new walletAdapterWallets.CoinhubWalletAdapter(),
      new walletAdapterWallets.Coin98WalletAdapter(),
      new walletAdapterWallets.BitgetWalletAdapter(),
      new MobileAdapter.SolanaMobileWalletAdapter({
        addressSelector: MobileAdapter.createDefaultAddressSelector(),
        appIdentity: {
          name: "Mesh Protocol",
          uri: window.location.href,
          icon: "logo192.png",
        },
        authorizationResultCache: MobileAdapter.createDefaultAuthorizationResultCache(),
        // chain: WalletAdapterNetwork.Devnet,
        cluster: WalletAdapterNetwork.Mainnet,
        onWalletNotFound: MobileAdapter.createDefaultWalletNotFoundHandler(),
      }),
    ],
    []
  );
  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);

  const networkRPC = useMemo(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === Environment.prod) {
      if (rpc === RPC._1) {
        return HELIUS_MAINNET_RPC;
      } else if (rpc === RPC._2) {
        return QUICKNODE_MAINNET_RPC;
      } else return rpc;
    } else {
      if (rpc === RPC._1) {
        return HELIUS_DEVNET_RPC;
      } else if (rpc === RPC._2) {
        return clusterApiUrl("devnet");
      } else return rpc;
    }
  }, [rpc]);

  useEffect(() => {
    // Set up the interval
    const interval = setInterval(() => {
      dispatch(incrementSuccessTxCount());
    }, 30000); // 30000 milliseconds = 30 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array means this effect runs once on mount and clean-up on unmount

  return (
    <ConnectionProvider endpoint={networkRPC}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect={true}>
        <WalletModalProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
