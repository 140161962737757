import { Environment } from "Types/misc";
import { HELIUS_DEVNET_RPC, HELIUS_MAINNET_RPC } from "./endpoints";

export const NATIVE_SOL = "So11111111111111111111111111111111111111112";
export const MESH_TOTAL_SUPPLY = 30000000; //11031000

export const TELEGRAM_LINK = "https://t.me/meshprotocol";
export const X_HANDLE = "https://twitter.com/mesh_protocol";
export const EMAIL = "contact@meshprotocol.xyz";
export const GITBOOK = "https://docs.meshprotocol.xyz/";
export const MESH_WEBSITE = "https://meshprotocol.xyz";
export const AMOUNT_INPUT_REGEX = /^((\d+[.]?\d*)|(\.\d+))$/;
export const RADIYUM_URL =
  "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=MESHwqmXvAmKpDYSgRZkm9D5H8xYSCVixeyZoePHn4G&fixed=in";
export const MEXC_URL = "https://www.mexc.com/";
export const ORCA_URL = "https://www.orca.so/";

export const MESH_AGGREGATOR_URL = "https://beta.meshprotocol.xyz";
export const MESH_ANALYTICS_URL = "https://dune.com/luffythedev/mesh-protocol-token";

export const WALLET_BTN_LABELS = {
  "change-wallet": "Change Wallet",
  connecting: "Connecting ...",
  "copy-address": "Copy Address",
  copied: "Copied",
  disconnect: "Disconnect",
  "has-wallet": "Connect",
  "no-wallet": "Connect Wallet",
} as const;

export const networkRPC =
  process.env.REACT_APP_ENVIRONMENT === Environment.local
    ? "http://localhost:8899"
    : process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? HELIUS_DEVNET_RPC
    : HELIUS_MAINNET_RPC;

export const solanaExplorerUrl = "https://explorer.solana.com/";
