import { lazy } from "react";
import { useNavigate, useRoutes, Navigate } from "react-router-dom";

import CachedService from "../Classes/cachedService";
import MainLayout from "Layout/mainlayout";

const Home = lazy(() => import("Pages/Home"));

export default function Routes() {
  const navigate = useNavigate();
  CachedService.navigation = navigate;

  return useRoutes([
    // { index: true, element: <Navigate to={"/staking"} replace={true} /> },
    {
      path: "/",
      element: (
        <MainLayout>
          <Home />
        </MainLayout>
      ),
      // children: [
      //   { path: "staking", element: <Home /> },
      //   { path: "*", element: <Navigate to={"/staking"} replace={true} /> },
      // ],
    },
    { path: "*", element: <Navigate to={"/"} replace={true} /> },
  ]);
}
