import { useState } from "react";

import MeshLogo from "Assets/Svgs/Mesh_Logo.svg";
import MeshMobileLogo from "Assets/Svgs/mesh.svg";
import { WalletButton } from "App";
import { MESH_AGGREGATOR_URL, MESH_ANALYTICS_URL, WALLET_BTN_LABELS } from "Constants/misc";
import { TokenPrice } from "Components/tokenPrice";
import { Chain } from "Components/chainBox";
import { GetMeshBanner } from "Components/getMeshBanner";
import CachedService from "Classes/cachedService";

const Header = () => {
  const [closeBanner, setCloseBanner] = useState(false);
  return (
    <div>
      <div className="flex flex-row justify-between items-center bg-black w-full p-4 px-0 sm:px-14">
        <div className="flex gap-12 items-center">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => CachedService.navigation("/")}
          >
            <img
              src={MeshLogo}
              className="hidden md:block"
              alt="mesh-logo"
              width={110}
              height={70}
            />
            <img
              src={MeshMobileLogo}
              className="block md:hidden"
              alt="mesh-logo"
              width={45}
              height={45}
            />
          </div>
          <div className="hidden md:flex items-center gap-6">
            <a
              href={MESH_AGGREGATOR_URL}
              target="_blank"
              className="text-primary text-sm font-medium accent-on-hover"
              rel="noreferrer"
            >
              Aggregator
            </a>
            <a
              href={MESH_ANALYTICS_URL}
              target="_blank"
              className="text-primary text-sm font-medium accent-on-hover"
              rel="noreferrer"
            >
              Analytics
            </a>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <TokenPrice />
          <Chain />
          <div className="hidden md:block">
            <WalletButton
              labels={WALLET_BTN_LABELS}
              style={{ borderRadius: "4px", fontFamily: "inter" }}
            />
          </div>
        </div>
      </div>
      {!closeBanner && <GetMeshBanner onCrossClick={() => setCloseBanner(true)} />}
    </div>
  );
};

export default Header;

export const NetworkLabel = () => {
  return (
    <div className="network-label-bg text-sm font-medium text-primary ml-1 sm:ml-1 px-2 py-1 flex items-center rounded-sm">
      Devnet
    </div>
  );
};
