import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { HELIUS_DEVNET_RPC } from "Constants/endpoints";

import { AppReducer, RPC } from "Types/reducers";

const initialState: AppReducer = {
  meshPrice: 0,
  solPrice: 0,
  indexMeshPrice: 0,
  stakeInfoBannerClosed: "0",
  isTimerCompleted: false,
  isScreenTimerCompleted: false,
  isBannerTimerCompleted: false,
  getMeshBannerClosed: "0",
  rpc: RPC._1,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMeshPrice(state, action: PayloadAction<number | undefined>) {
      state.meshPrice = action.payload ?? 0;
    },
    setSolPrice(state, action: PayloadAction<number | undefined>) {
      state.meshPrice = action.payload ?? 0;
    },
    setStakeInfoBannerClosed(state, action: PayloadAction<number>) {
      state.stakeInfoBannerClosed = action.payload.toString();
    },
    setGetMeshBannerClosed(state, action: PayloadAction<number>) {
      state.getMeshBannerClosed = action.payload.toString();
    },
    setBothTokenPrices(
      state,
      action: PayloadAction<{ mesh: number | undefined; sol: number | undefined }>
    ) {
      state.meshPrice = action.payload.mesh ?? 0;
      state.solPrice = action.payload.sol ?? 0;
      state.indexMeshPrice = 0;
    },
    setIsTimerCompleted(state) {
      state.isTimerCompleted = true;
    },
    setIsScreenTimerCompleted(state) {
      state.isScreenTimerCompleted = true;
    },
    setIsBannerTimerCompleted(state) {
      state.isBannerTimerCompleted = true;
    },
    setRPC(state, action: PayloadAction<RPC>) {
      state.rpc = action.payload;
    },
  },
});

export default app.reducer;

export const {
  setMeshPrice,
  setSolPrice,
  setBothTokenPrices,
  setStakeInfoBannerClosed,
  setIsTimerCompleted,
  setIsScreenTimerCompleted,
  setGetMeshBannerClosed,
  setRPC,
} = app.actions;
