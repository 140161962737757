export enum TwBreakPoints {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export enum Environment {
  local = "local",
  dev = "dev",
  prod = "prod",
}

export enum ContractSeeds {
  global_state = "global_state",
  funds_controller = "funds_controller",
  user_info = "user_info",
}
