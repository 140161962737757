import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadingsReducer } from "Types/reducers";

const initialState: LoadingsReducer = {
  isPendingRewardLoading: false,
  isPriceLoading: false,
  isUserStakingInfoLoading: false,
  isGlobalStateLoading: false,
  isTxInProgress: false,
};

const loadings = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setisPendingRewardLoading(state, action: PayloadAction<boolean>) {
      state.isPendingRewardLoading = action.payload;
    },
    setIsPriceLoading(state, action: PayloadAction<boolean>) {
      state.isPriceLoading = action.payload;
    },
    setIsUserStakingInfoLoading(state, action: PayloadAction<boolean>) {
      state.isUserStakingInfoLoading = action.payload;
    },
    setIsGlobalStateLoading(state, action: PayloadAction<boolean>) {
      state.isGlobalStateLoading = action.payload;
    },
    setIsTxInProgress(state, action: PayloadAction<boolean>) {
      state.isTxInProgress = action.payload;
    },
  },
});

export default loadings.reducer;

export const {
  setisPendingRewardLoading,
  setIsPriceLoading,
  setIsUserStakingInfoLoading,
  setIsGlobalStateLoading,
  setIsTxInProgress,
} = loadings.actions;
